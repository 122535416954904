<template>
	<v-content class="menu">
	
		<SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'menu_shops', query: { feature_id: 'menu'}}" ></SelectedShop>

		<h1 class="categorytitle mt-5">{{category_name}}</h1>

    <div class="text-center mx-12">
      <v-divider class="mx-12 mb-5" color="maincolor"></v-divider>
    </div>
	
  <div class="px-4" v-if="items">
		<v-card class="mb-5" v-for="item in items" :key="item.id">
			<v-img class="" v-if="item.image_path" height="165px" :src="item.image_path" lazy-src="@/assets/nophoto.png" @click.stop="onClickBtn(item)">
				<template v-slot:placeholder>
					<v-row class="fill-height ma-0" align="center" justify="center">
	<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
	</v-row>
				</template>
			</v-img>
	
			<v-card-title class="subtitle-2 pb-0">{{item.title}}</v-card-title>
			<v-card-text class="price pb-0">{{item.price}}</v-card-text>
	
			<v-card-text class="pb-2">
				{{item.explain}}
			</v-card-text>
	
			<div class="text-right fontsize13 pt-2 px-4 pb-4" v-if="item.image_link">
				<v-icon x-small class="maincolor">fas fa-link</v-icon> <a :href="item.image_link" target="_blank" class="maincolor">{{item.image_link_name}}</a>
			</div>

		</v-card>
	</div>

			<v-dialog v-model="dialog" content-class="no_radius">
                <v-card class="pa-0" tile>
                    <v-img class="" :src="currentimage" lazy-src="@/assets/nophoto.png"></v-img>
                </v-card>
            </v-dialog>

    <!-- InfiniteLoading -->
    <infinite-loading ref="infiniteLoading"  @infinite="infiniteHandler" spinner="spiral">
			<div slot="spinner"><v-progress-circular :size="30" :width="2" class="maincolor" indeterminate></v-progress-circular></div>
			<div slot="no-more"></div>
			<div slot="no-results">No data</div>
		</infinite-loading>


	</v-content>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import SelectedShop from '@/components/selected_shop.vue'
export default {
    components: {
        InfiniteLoading,
        SelectedShop,
    },
	data: () => ({
    page: 1,
		dialog: false,
		currentimage: null,
		items: [],
		init: [],
    category_name: null,
	}),
	methods: {
		onClickBtn(item) {
			this.currentimage = item.image_path
			this.dialog = true
		},
    created () {
      this.init = this.storageGet('*')

      console.log('init!', this.init);
      var selected_shop = this.getUrlGetParam('selected_shop');

      if (selected_shop) {
          this.selected_shop = selected_shop
      } else {
          this.selected_shop = '全て'
      }
    },

        async infiniteHandler() {

            this.init = this.storageGet('*')

            var category_id = this.getUrlGetParam('category_id');
            var selected_shop = this.getUrlGetParam('selected_shop');

            if (selected_shop) {
                this.selected_shop = selected_shop
            } else {
                this.selected_shop = '全て'
            }

            var params = {
                'page': this.page
            } 

            if (category_id) {
                params['category_id'] = category_id
            }

            var reqest_params = { params: params}

            var res = await this.apiCall('/menu/list', reqest_params)

            if(res.data){
              if (this.page == 1) {
                  this.$set(this, 'category_name', res.category_name)
                  this.items = res.data
              } else {
                  this.items = this.items.concat(res.data);
              }
            }
            
              if (res.data && res.data.length > 0) {
                  this.$refs.infiniteLoading.stateChanger.loaded()
              } else {
                  this.$refs.infiniteLoading.stateChanger.complete()
              }
            this.page = this.page + 1
        },
	}
}
</script>

<style scoped>
.price {
	font-size: 13px;
	color: #9D9D9D;
}

.categorytitle {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

</style>
